import { createGlobalStyle } from 'styled-components';

import { color } from './theme/functions';
import transitions from './theme/transitions';

import 'slick-carousel/slick/slick.css';

const GlobalStyles = createGlobalStyle`
  * {
    box-sizing: border-box;
    box-shadow: none;
    outline: none;
    font-family: ${({ theme: { fonts } }) => fonts?.primary};
  }

  html {
    height: 100%;
    margin: 0;
    font-size: 16px;
    font-weight: 450;
  }

  body {
    height: 100%;
    margin: 0;
    padding: 0;
    color: ${color('main_blue')};
    background-color: ${color('viewport_background')};
    -webkit-font-smoothing:antialiased;
    -moz-osx-font-smoothing:grayscale;
    transition: background .2s ease-in-out;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p {
    margin: 0
  }

  /* Load imported transitions */
  ${transitions}

  #root {
    display: flex;
    flex-direction: column;
    height: 100%;
  }

  fieldset {
    padding: 0;
    margin: 0;
    border: none;
  }

  textarea {
    resize: none;
  }

  .no-scroll {
    overflow: hidden;
  }

  `;

export default GlobalStyles;
