import styled from 'styled-components';

import { color } from '../../theme/functions';
import { MIN_WIDTH_MD } from '../../theme/breakpoints';
import { INSURANCE_TYPE_DENTOLO, INSURANCE_TYPE_PETOLO, INSURANCE_TYPE_VITOLO } from '../../shared';

import { Link } from '../ui';
import { Container } from '../common';
import { variantStyles } from '../ui/styled/Button.styled';

const infoTextcolorMap = {
  [INSURANCE_TYPE_DENTOLO]: color('professional_light_blue'),
  [INSURANCE_TYPE_PETOLO]: color('professional_light_blue'),
  [INSURANCE_TYPE_VITOLO]: color('dusty_blue'),
};

export const StyledPolicyDetailsContainer = styled(Container)`
  max-width: 840px;
`;

export const StyledPolicyDetailsTitle = styled.h4`
  font-size: 1.1rem;
  font-weight: 700;
  color: ${color('main_blue')};
  opacity: 0.5;

  @media (min-width: ${MIN_WIDTH_MD}) {
    font-size: 1.25rem;
  }
`;

export const StyledPolicyDetailsPolicyName = styled.h3`
  font-size: 1.5rem;
  font-weight: 700;
  color: ${color('main_blue')};

  @media (min-width: ${MIN_WIDTH_MD}) {
    font-size: 1.875rem;
  }
`;

export const StyledPolicyDetailsLink = styled(Link)`
  margin: 1.5rem auto 0;
`;

export const StyledPolicyDetailsText = styled.div`
  font-size: 1.1rem;
  font-weight: 450;
  color: ${({ theme }) => infoTextcolorMap[theme?.name] || color('professional_light_blue')};

  a {
    ${variantStyles('nostyle')}
  }

  @media (min-width: ${MIN_WIDTH_MD}) {
    font-size: 1.185rem;
  }
`;

export const StyledPolicyDetailsTextLg = styled.div`
  font-size: 1.25rem;
  font-weight: 700;
  color: ${color('main_blue')};
  text-align: ${({ textAlign }) => textAlign || 'left'};
`;

export const StyledPolicyDetailsSectionTitle = styled(StyledPolicyDetailsTextLg)`
  margin-bottom: 1rem;
`;

export const StyledPolicyDetailsList = styled.div`
  display: flex;
  align-items: ${({ alignItems }) => alignItems || 'center'};

  & + & {
    margin-top: 1rem;
  }
`;

export const StyledPolicyDetailsNote = styled(StyledPolicyDetailsText)`
  margin-top: 1.5rem;
  color: ${color('main_blue')};
`;
