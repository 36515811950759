import styled from 'styled-components';

import { MIN_WIDTH_MD } from '../../../theme/breakpoints';

import { BackButton } from '../../../components/common';

export const StyledButtonContainer = styled.div`
  margin-top: 2em;
  text-align: center;
  display: none;

  > button {
    margin: auto;
  }

  @media only screen and (min-width: ${MIN_WIDTH_MD}) {
    display: block;
  }
`;

export const StyledImg = styled.img`
  width: 100%;
  margin-left: 0;
  margin-top: 3em;
  @media only screen and (min-width: ${MIN_WIDTH_MD}) {
    margin-left: -2em;
    margin-top: 0;
  }
`;
export const StyledBackButton = styled(BackButton)`
  display: flex;

  @media only screen and (min-width: ${MIN_WIDTH_MD}) {
    display: none;
  }
`;
