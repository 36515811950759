import styled from 'styled-components';

import { MIN_WIDTH_MD } from '../../theme/breakpoints';
import { color } from '../../theme/functions';

import { Button as LoginButton } from '../ui';
import { Checkbox, Container } from '../common';
import { THEME_DENTOLO, THEME_PETOLO, THEME_VITOLO } from '../../shared';

export const AuthContainer = styled(Container)`
  margin-top: 2rem;
`;

export const PageTitle = styled.h1`
  margin-bottom: 1.5rem;
  font-weight: 700;
  font-size: 1.5rem;
  line-height: 44px;

  @media (min-width: ${MIN_WIDTH_MD}) {
    font-size: 2.25rem;
  }
`;

export const DateContainer = styled.div`
  display: flex;
  justify-content: space-between;

  > div + div {
    margin-left: 0.625rem;
  }

  @media (min-width: ${MIN_WIDTH_MD}) {
    > div {
      label {
        min-width: auto;
      }

      + div {
        margin-left: 2.75rem;
      }
    }
  }
`;

export const StyledRememberCheckbox = styled(Checkbox)`
  margin-top: 1rem;
`;

export const Button = styled(LoginButton)`
  display: flex;
  align-items: center;
  width: auto;
  margin: 0 auto;

  @media (min-width: ${MIN_WIDTH_MD}) {
    display: block;
    margin-left: 0;
  }
`;

const getTipBackgroundColor = (theme) => {
  switch (theme?.name) {
    case THEME_DENTOLO:
      return color('clear_light_blue');
    case THEME_PETOLO:
      return color('ultra_light_orange');
    case THEME_VITOLO:
      return color('light_violet');
    default:
      return color('clear_light_blue');
  }
};

export const StyledLoginTipContainer = styled.div`
  padding: 1rem;
  background-color: red;
  margin-top: 1.5rem;
  display: flex;
  justify-content: space-between;
  background-color: ${({ theme }) => {
    return getTipBackgroundColor(theme);
  }};
`;

export const StyledBoldTip = styled.span`
  font-weight: 700;
`;
