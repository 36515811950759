// Add slides/cards for the `PromotionCarousel` in this file
import React from 'react';

import {
  DA_DIREKT_POLICY_CATEGORIES,
  DENTOLO_DENTAL_POLICY_CATEGORIES,
  PETOLO_PET_HEALTH_POLICY_CATEGORIES,
  PETOLO_PET_LIABILITY_POLICY_CATEGORIES,
  RISK_CARRIER_AMTRUST,
  SPECIAL_TARIFF_POLICY_CATEGORIES,
} from '../../../shared';

import ReferralLinkCardDentolo from '../ReferralLinkCard/ReferralLinkCardDentolo';
import ReferralLinkCardPetolo from '../ReferralLinkCard/ReferralLinkCardPetolo';
import VitoloCrossSellingCard from '../VitoloCrossSellingCard/VitoloCrossSellingCard';
import VitoloBetterDoc from '../VitoloBetterDoc/VitoloBetterDoc';
import ReferralLinkCardDaDirektDental from '../ReferralLinkCard/ReferralLinkCardDaDirektDental';
import ReferralLinkCardDaDirektPet from '../ReferralLinkCard/ReferralLinkCardDaDirektPet';
import FressnapfFriendsCard from '../FressnapfFriendsCard';

/*
  - `hidden` property hides the card from policyCategories/riskCarriers in its value
  - Empty array will not render the carousel
*/

// DENTOLO
export const promotionSlidesDentolo = [
  {
    id: 'vitolo-cross-selling-dentolo',
    card: <VitoloCrossSellingCard />,
    hidden: [...DA_DIREKT_POLICY_CATEGORIES],
  },
  {
    id: 'link-referral-dentolo',
    card: <ReferralLinkCardDentolo />,
    hidden: [...DA_DIREKT_POLICY_CATEGORIES, RISK_CARRIER_AMTRUST],
  },
  {
    id: 'link-referral-da-direkt-dental',
    card: <ReferralLinkCardDaDirektDental />,
    hidden: [...DENTOLO_DENTAL_POLICY_CATEGORIES, RISK_CARRIER_AMTRUST],
  },
];

// PETOLO
export const promotionSlidesPetolo = [
  {
    id: 'vitolo-cross-selling-petolo',
    card: <VitoloCrossSellingCard />,
    hidden: [...DA_DIREKT_POLICY_CATEGORIES, ...SPECIAL_TARIFF_POLICY_CATEGORIES],
  },
  {
    id: 'link-referral-petolo',
    card: <ReferralLinkCardPetolo />,
    hidden: [
      ...DA_DIREKT_POLICY_CATEGORIES,
      ...PETOLO_PET_LIABILITY_POLICY_CATEGORIES,
      ...SPECIAL_TARIFF_POLICY_CATEGORIES,
      RISK_CARRIER_AMTRUST,
    ],
  },
  {
    id: 'link-referral-da-direkt-dental',
    card: <ReferralLinkCardDaDirektPet />,
    hidden: [
      ...PETOLO_PET_HEALTH_POLICY_CATEGORIES,
      ...PETOLO_PET_LIABILITY_POLICY_CATEGORIES,
      ...SPECIAL_TARIFF_POLICY_CATEGORIES,
    ],
  },
  {
    id: 'link-fressnapf-friends',
    card: <FressnapfFriendsCard />,
    hidden: [
      ...PETOLO_PET_LIABILITY_POLICY_CATEGORIES,
      ...DA_DIREKT_POLICY_CATEGORIES,
      ...SPECIAL_TARIFF_POLICY_CATEGORIES,
    ],
  },
];

// Vitolo
export const promotionSlidesVitolo = [
  {
    id: 'vitolo-better-doc',
    card: <VitoloBetterDoc />,
  },
];
