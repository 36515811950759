import styled, { css } from 'styled-components';
import PropTypes from 'prop-types';

import { color, boxShadow } from '../../theme/functions';

const getBackgroundColor = ({ filled, transparent }) => {
  if (transparent) return 'transparent';
  if (filled) return color('main_blue');

  return 'white';
};

const StyledPaper = styled.div`
  padding: 1rem;
  border-radius: 5px;
  color: ${({ filled }) => (filled ? color('white') : 'inherit')};
  background-color: ${getBackgroundColor};

  ${({ hasShadow }) =>
    hasShadow &&
    css`
      box-shadow: ${boxShadow('box_shadow_1')};
    `};
`;

export default StyledPaper;

StyledPaper.defaultProps = {
  hasShadow: true,
  filled: false,
};

StyledPaper.propTypes = {
  hasShadow: PropTypes.bool,
  filleds: PropTypes.bool,
};
