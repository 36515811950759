import styled from 'styled-components';

import { MIN_WIDTH_MD } from '../../theme/breakpoints';
import { color } from '../../theme/functions';

const StyledTitle = styled.h1`
  margin-bottom: 0.5rem;
  font-size: 1.25rem;
  font-weight: 700;
  color: ${color('main_blue')};
  line-height: 28px;

  @media (min-width: ${MIN_WIDTH_MD}) {
    font-size: 1.5rem;
    margin-bottom: 1rem;
  }
`;
export default StyledTitle;
