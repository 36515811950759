import React from 'react';
import { useIntl, FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import { CSSTransition } from 'react-transition-group';

import { useReady } from '../../shared';
import { MIN_WIDTH_MD } from '../../theme/breakpoints';

import { InfoWindow } from '../ui';
import { Container, PageTitle, Paper } from '../common';

const StyledPaper = styled(Paper)`
  padding: 2rem 1rem;

  @media (min-width: ${MIN_WIDTH_MD}) {
    padding: 2rem 3.125rem;
  }

  > div {
    > p {
      span {
        font-size: 1.125rem;
      }
    }
  }
`;

const StyledPageTitle = styled(PageTitle)`
  font-size: 2rem;
  text-align: center;
`;

function SecondAuthStatus() {
  const intl = useIntl();
  const ready = useReady();

  const infoSettings = {
    mode: 'failure',
    titleHtml: intl.formatMessage({ id: 'second.auth.status.failure.title' }),
    text: intl.formatMessage({ id: 'second.auth.status.failure.text' }),
    button: {
      text: intl.formatMessage({ id: 'second.auth.status.failure.button.text' }),
      to: '/',
    },
  };

  return (
    <CSSTransition in={ready} timeout={600} classNames="slow-fade" unmountOnExit>
      <Container>
        <StyledPageTitle>
          <FormattedMessage id="second.auth.status.title" />
        </StyledPageTitle>

        <StyledPaper>
          <InfoWindow
            mode={infoSettings.mode}
            titleHtml={infoSettings.titleHtml}
            textHtml={infoSettings.text}
            button={infoSettings.button}
          />
        </StyledPaper>
      </Container>
    </CSSTransition>
  );
}

export default SecondAuthStatus;
