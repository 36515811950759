import styled, { css } from 'styled-components';
import PropTypes from 'prop-types';

import { MIN_WIDTH_MD } from '../../theme/breakpoints';
import { color } from '../../theme/functions';

const StyledText = styled.p`
  ${(props) => {
    const { fontSize, fontWeight, fontColor, lineHeight } = props;

    return css`
      color: ${fontColor};
      font-size: ${`calc(${fontSize} - 25%)}`};
      font-weight: ${fontWeight};
      line-height: ${lineHeight};

      @media (min-width: ${MIN_WIDTH_MD}) {
        font-size: ${fontSize};
      }
    `;
  }}
`;

export default StyledText;

StyledText.defaultProps = {
  fontSize: '1.25rem',
  fontWeight: 450,
  fontColor: color('main_blue'),
  lineHeight: '1.5rem',
};

StyledText.propTypes = {
  fontSize: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  fontWeight: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  fontColor: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  lineHeight: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};
