import React, { useState } from 'react';
import { CSSTransition } from 'react-transition-group';
import { useMutation, useQuery } from '@apollo/client';
import { FormattedMessage } from 'react-intl';

import {
  CREATE_LEAD_WITH_CUSTOMER_DATA,
  PETOLO_PET_HEALTH_POLICY_CATEGORIES,
  GET_CUSTOMER,
  PETOLO_INSURE_ANOTHER_PET_URL,
  imgLinks,
} from '../../../shared';

import { Button } from '../../ui';
import {
  StyledInsureAnotherPetBanner,
  StyledInsureAnotherPetBannerCloseButton,
  StyledInsureAnotherPetBannerContainer,
  StyledInsureAnotherPetBannerContent,
  StyledInsureAnotherPetBannerContentDescription,
  StyledInsureAnotherPetBannerContentTitle,
  StyledInsureAnotherPetBannerPetImage,
  StyledInsureAnotherPetBannerPetoloLogo,
} from './InsureAnotherPetBanner.styled';
import {
  SplashImageBottomLeftCorner,
  SplashImageBottomRight,
  SplashImageTopCenter,
  SplashImageTopRightCorner,
  SplashImageTopRightCornerMobile,
} from './SplashImages';

const paramUtmCampaign = 'account_area_referral_bottombanner';

const goToFunnel = ({ uuid }) => {
  if (!uuid) return;

  const urlToInsureAnotherPetFunnel = `${PETOLO_INSURE_ANOTHER_PET_URL}&uuid=${uuid}&utm_campaign=${paramUtmCampaign}`;

  window.open(urlToInsureAnotherPetFunnel, '_blank');
};

function InsureAnotherPetBanner() {
  const { data: customerData } = useQuery(GET_CUSTOMER);
  const customer = customerData?.customer;
  const { contract } = customer;

  const [showBanner, setShowBanner] = useState(true);

  const [
    createLeadWithCustomerData,
    { data: response, called: newUuidCalled, loading: newUuidLoading },
  ] = useMutation(CREATE_LEAD_WITH_CUSTOMER_DATA);

  const newLeadUuid = response?.createLeadWithCustomerData?.uuid;

  const allowedCategories = [...PETOLO_PET_HEALTH_POLICY_CATEGORIES];

  const handleCloseBanner = (ev) => {
    if (ev) ev.preventDefault();

    setShowBanner(false);
  };

  const handleRequestComplete = (data) => {
    const newUuid = data?.createLeadWithCustomerData?.uuid;

    if (!newUuid) return;

    goToFunnel({ uuid: newUuid });
  };

  const handleInsureAnotherPetBtnClick = () => {
    if (!newLeadUuid) {
      createLeadWithCustomerData({
        onCompleted: handleRequestComplete,
      });
    } else {
      goToFunnel({ uuid: newLeadUuid });
    }
  };

  const shouldHideBanner = !showBanner || !allowedCategories?.includes(contract?.policyCategory);
  const disableButton = newUuidCalled && newUuidLoading;

  if (shouldHideBanner) return null;

  return (
    <CSSTransition timeout={400} classNames="fade-in">
      <StyledInsureAnotherPetBannerContainer>
        <SplashImageBottomLeftCorner />
        <SplashImageTopCenter />
        <SplashImageTopRightCorner />
        <SplashImageBottomRight />
        <SplashImageTopRightCornerMobile />

        <StyledInsureAnotherPetBannerCloseButton
          icon="icon.close"
          size="xs"
          alt="close"
          onClick={handleCloseBanner}
        />

        <StyledInsureAnotherPetBanner>
          <StyledInsureAnotherPetBannerPetoloLogo
            src={imgLinks['icon.menu.petolo.logo']}
            alt="petolo"
          />

          <StyledInsureAnotherPetBannerContent>
            <StyledInsureAnotherPetBannerContentTitle fontSize="1.5rem" fontWeight={700}>
              <FormattedMessage id="insure_another_pet.banner.title" />
            </StyledInsureAnotherPetBannerContentTitle>

            <StyledInsureAnotherPetBannerContentDescription fontSize="1rem">
              <FormattedMessage id="insure_another_pet.banner.description" />
            </StyledInsureAnotherPetBannerContentDescription>

            <Button type="button" onClick={handleInsureAnotherPetBtnClick} disabled={disableButton}>
              <FormattedMessage id="insure_another_pet.banner.button.label" />
            </Button>
          </StyledInsureAnotherPetBannerContent>

          <StyledInsureAnotherPetBannerPetImage
            src={imgLinks['image.insure.another.pet.banner']}
            alt="pets"
          />
        </StyledInsureAnotherPetBanner>
      </StyledInsureAnotherPetBannerContainer>
    </CSSTransition>
  );
}

export default InsureAnotherPetBanner;
