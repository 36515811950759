import styled from 'styled-components';

import { THEME_DENTOLO, THEME_PETOLO, THEME_VITOLO } from '../../shared';
import { color } from '../../theme/functions';

const backgroundColor = {
  [THEME_DENTOLO]: color('main_blue'),
  [THEME_PETOLO]: color('ultra_light_orange'),
  [THEME_VITOLO]: color('main_blue'),
};

const linkColor = {
  [THEME_DENTOLO]: color('secondary'),
  [THEME_PETOLO]: color('secondary'),
  [THEME_VITOLO]: color('vibrant_light_violet'),
};

const linkHoverColor = {
  [THEME_DENTOLO]: color('clear_light_blue'),
  [THEME_PETOLO]: color('main_orange'),
  [THEME_VITOLO]: color('clear_light_blue'),
};

const copyrightColor = {
  [THEME_DENTOLO]: color('white'),
  [THEME_PETOLO]: color('main_orange'),
  [THEME_VITOLO]: color('white'),
};

export const StyledFooterContainer = styled.footer`
  flex-shrink: 0;
  padding: 1.5rem 1rem;
  background-color: ${({ theme }) => backgroundColor[theme?.name] || color('main_blue')};
`;

export const StyledFooterWrapper = styled.div`
  max-width: 960px;
  margin: auto;
`;

export const StyledFooterLinksWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  row-gap: 0.5rem;
  font-size: 1rem;
  font-weight: 450;

  > button {
    margin: 0;
    padding: 0;
    font-size: 1rem;
    font-weight: 450;
    background: transparent;
    border: none;
  }

  > a,
  > button {
    position: relative;
    color: ${({ theme }) => linkColor[theme?.name] || color('secondary')};
    text-decoration: none;
    transition: color 0.2s ease-out;
    cursor: pointer;

    &::after {
      content: '';
      position: absolute;
      height: 2px;
      bottom: 0;
      left: 0;
      right: 0;
      width: 100%;
      background-color: ${({ theme }) => linkColor[theme?.name] || color('clear_light_blue')};
      transform: scaleX(0);
      transform-origin: bottom right;
      transition: transform 0.2s ease-out;
    }

    &:hover,
    &:active,
    &:focus {
      color: ${({ theme }) => linkHoverColor[theme?.name] || color('clear_light_blue')};
    }

    &:active,
    &:focus {
      &::after {
        transform: scaleX(1);
        transform-origin: bottom left;
      }
    }
  }
`;

export const StyledFooterLinkSeparator = styled.span`
  margin: 0 1rem;
  font-size: 1.25rem;
  color: ${({ theme }) => linkColor[theme?.name] || color('secondary')};
`;

export const StyledFooterCopyright = styled.div`
  margin-top: 1.5rem;
  font-size: 1rem;
  font-weight: 450;
  color: ${({ theme }) => copyrightColor[theme?.name] || color('white')};
`;

export const StyledBranchName = styled.div`
  margin-top: 1em;
  color: #999;
  text-align: right;
`;
