import styled from 'styled-components';

import { color } from '../../../theme/functions';

import Input from '../Input';

export const StyledInputCurrencyContainer = styled.div`
  position: relative;
`;

export const StyledInputCurrency = styled(Input)`
  padding-right: 2.5rem;
`;

export const StyledSymbolSuffix = styled.span`
  position: absolute;
  top: 50%;
  right: 1rem;
  transform: translateY(-50%);
  font-size: 1rem;
  font-weight: 450;
  color: ${color('main_blue')};
`;
