import styled, { css } from 'styled-components';

import { MAX_WIDTH_SM, MIN_WIDTH_MD } from '../../theme/breakpoints';

import { IconButton, Paper } from '../common';
import { color } from '../../theme/functions';

export const StyledCustomerDetailsCard = styled(Paper)`
  & + & {
    margin-top: 1rem;
  }
`;

export const StyledCustomerDetailsCardTitle = styled.h5`
  font-size: 1.25rem;
  font-weight: 700;
  margin-bottom: 1rem;

  ${({ noSpacing }) =>
    noSpacing &&
    css`
      margin-bottom: 0;
    `}
`;

export const StyledCustomerDetailsCardTitleButtonWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;
`;

export const StyledCustomerDetailsModalButton = styled(IconButton)`
  @media (max-width: ${MAX_WIDTH_SM}) {
    width: 1.5rem;
    height: 1.5rem;
  }
`;

export const StyledCustomerDetailsButtonContainer = styled.div`
  margin-top: 1.25rem;

  > a {
    margin: 0 auto;
  }

  @media (min-width: ${MIN_WIDTH_MD}) {
    > a {
      margin-left: 0;
    }
  }
`;

export const StyledCustomerDetailsItem = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  column-gap: 2rem;
  font-size: 1rem;

  & + & {
    margin-top: 0.5rem;
  }

  > div[data-value] {
    font-weight: 700;
  }

  @media (min-width: ${MIN_WIDTH_MD}) {
    flex-direction: row;

    > div[data-label] {
      flex: 0 1 130px;
    }

    & + & {
      margin-top: 1rem;
    }
  }
`;

export const StyledPaymentDetailsItem = styled(StyledCustomerDetailsItem)`
  flex-direction: row;
  margin-bottom: 1rem;
  column-gap: 0.5rem;
  align-items: center;

  > div[data-label] {
    flex: none;
  }
  > div[data-pending] {
    color: ${color('gray')};
    font-size: 1rem;
    > p[data-pending-bold] {
      font-weight: 700;
    }
    > p[data-pending-normal] {
      font-weight: 450;
    }
  }

  > div[data-value] {
    > p[data-label] {
      font-weight: 100;
    }
  }
`;

export const StyledPaymentErrorContainer = styled.div`
  background-color: ${color('ultra_light_gray')};
  padding: 0.5rem;
  border-radius: 0.313rem;
  color: ${color('gray')};
  margin-bottom: 1rem;
  font-size: 0.875rem;
`;

export const StyledLoadingStatus = styled.div`
  align-self: flex-start;
  width: fit-content;
  padding: 0.25em 0.375em;
  font-weight: 700;
  line-height: 1em;
  color: ${color('main_blue')};
  background-color: ${color('gray_blue')};
  border-radius: 5px;
  margin-bottom: 1rem;
  span {
    font-size: 0.75em;
  }
`;
