import React from 'react';
import { FormattedMessage } from 'react-intl';

import useAvailableCancellationDate from './useAvailableCancellationDate';
import {
  WITHDRAWAL_CANCELLATION_NO_PAYMENT_NO_CLAIM,
  WITHDRAWAL_CANCELLATION_EXPORTED_PAYMENT_NO_CLAIM,
  WITHDRAWAL_CANCELLATION_WITH_CLAIM,
  WITHDRAWAL_CANCELLATION_EXPORTED_PAYMENT_WITH_CLAIM,
  REGULAR_CANCELATION_INSIDE_MINIMUM_CONTRACT_DURATION_WITH_PAYOUT,
  REGULAR_CANCELATION_WITHOUT_EXPORTED_PAYMENTS_AND_CLAIMS,
  REGULAR_CANCELATION_EXPORTED_PAYMENTS_WITHOUT_CLAIMS,
  REGULAR_CANCELATION_CLAIMS_WITHOUT_EXPORTED_PAYMENTS,
  REGULAR_CANCELATION_WITH_EXPORTED_PAYMENTS_AND_CLAIMS,
  REGULAR_CANCELATION_WITH_IM_COVERAGE,
  REGULAR_CANCELATION_WITH_VIDEO_CONSULTATION,
} from '../ContractCancellationConstants';

import {
  StyledCancellationDate,
  StyledLandingPageBoldText,
  StyledLandingPageNormalText,
  StyledButtonContainer,
  StyledButton,
} from './LandingPage.styled';

// withdrawal_cancellation_no_payment_no_claim
function LandingPageContentsWithdrawalCancellationNoPaymentNoClaim({
  handleExit,
  handleCancelContract,
  disableButton,
}) {
  return (
    <>
      <StyledLandingPageNormalText>
        <FormattedMessage id="cancellation.contract.landing.page.warning1" tagName="p" />
      </StyledLandingPageNormalText>

      <StyledLandingPageBoldText>
        <FormattedMessage id="cancellation.contract.landing.page.question" tagName="p" />
      </StyledLandingPageBoldText>

      <StyledButtonContainer>
        <StyledButton variant="outline" onClick={handleExit}>
          <FormattedMessage id="cancellation.contract.cancel.exit" />
        </StyledButton>

        <StyledButton variant="primary" onClick={handleCancelContract} disabled={disableButton}>
          <FormattedMessage id="cancellation.contract.confirm.continue" />
        </StyledButton>
      </StyledButtonContainer>
    </>
  );
}

// withdrawal_cancellation_exported_payment_no_claim
function LandingPageContentsWithdrawalCancellationExportedPaymentNoClaim({
  handleExit,
  handleCancelContract,
  disableButton,
}) {
  return (
    <>
      <StyledLandingPageNormalText>
        <FormattedMessage id="cancellation.contract.landing.page.warning1" tagName="p" />
      </StyledLandingPageNormalText>

      <StyledLandingPageNormalText>
        <FormattedMessage id="cancellation.contract.landing.page.warning2" tagName="p" />
      </StyledLandingPageNormalText>

      <StyledLandingPageBoldText>
        <FormattedMessage id="cancellation.contract.landing.page.question" tagName="p" />
      </StyledLandingPageBoldText>

      <StyledButtonContainer>
        <StyledButton variant="outline" onClick={handleExit}>
          <FormattedMessage id="cancellation.contract.cancel.exit" />
        </StyledButton>

        <StyledButton variant="primary" onClick={handleCancelContract} disabled={disableButton}>
          <FormattedMessage id="cancellation.contract.confirm.continue" />
        </StyledButton>
      </StyledButtonContainer>
    </>
  );
}

// withdrawal_cancellation_with_claim
function LandingPageContentsWithdrawalCancellationWithClaim({
  handleExit,
  handleCancelContract,
  disableButton,
}) {
  return (
    <>
      <StyledLandingPageNormalText>
        <FormattedMessage id="cancellation.contract.landing.page.warning1" tagName="p" />
      </StyledLandingPageNormalText>

      <StyledLandingPageNormalText>
        <FormattedMessage id="cancellation.contract.landing.page.warning3" tagName="p" />
      </StyledLandingPageNormalText>

      <StyledLandingPageBoldText>
        <FormattedMessage id="cancellation.contract.landing.page.question" tagName="p" />
      </StyledLandingPageBoldText>

      <StyledButtonContainer>
        <StyledButton variant="outline" onClick={handleExit}>
          <FormattedMessage id="cancellation.contract.cancel.exit" />
        </StyledButton>

        <StyledButton variant="primary" onClick={handleCancelContract} disabled={disableButton}>
          <FormattedMessage id="cancellation.contract.confirm.continue" />
        </StyledButton>
      </StyledButtonContainer>
    </>
  );
}

// withdrawal_cancellation_exported_payment_with_claim
function LandingPageContentsWithdrawalCancellationExportedPaymentWithClaim({
  handleExit,
  handleCancelContract,
  disableButton,
}) {
  return (
    <>
      <StyledLandingPageNormalText>
        <FormattedMessage id="cancellation.contract.landing.page.warning1" tagName="p" />
      </StyledLandingPageNormalText>

      <StyledLandingPageNormalText>
        <FormattedMessage id="cancellation.contract.landing.page.warning4" tagName="p" />
      </StyledLandingPageNormalText>

      <StyledLandingPageBoldText>
        <FormattedMessage id="cancellation.contract.landing.page.question" tagName="p" />
      </StyledLandingPageBoldText>

      <StyledLandingPageNormalText>
        <FormattedMessage id="cancellation.contract.landing.page.warning2" tagName="p" />
      </StyledLandingPageNormalText>

      <StyledButtonContainer>
        <StyledButton variant="outline" onClick={handleExit}>
          <FormattedMessage id="cancellation.contract.cancel.exit" />
        </StyledButton>

        <StyledButton variant="primary" onClick={handleCancelContract} disabled={disableButton}>
          <FormattedMessage id="cancellation.contract.confirm.continue" />
        </StyledButton>
      </StyledButtonContainer>
    </>
  );
}

// regular_cancelation_inside_minimum_contract_duration_with_payout
function LandingPageContentsRegularCancelationInsideMinimumContractDurationWithPayout({
  handleExit,
  handleCancelContract,
  disableButton,
}) {
  const availableCancellationDate = useAvailableCancellationDate();

  return (
    <>
      <StyledLandingPageNormalText>
        <FormattedMessage id="cancellation.contract.landing.page.regular.text1" tagName="p" />
      </StyledLandingPageNormalText>

      {availableCancellationDate && (
        <>
          <StyledLandingPageBoldText>
            <FormattedMessage
              id="cancellation.contract.landing.page.regular.next_possible_date"
              tagName="p"
            />
          </StyledLandingPageBoldText>

          <StyledCancellationDate>{availableCancellationDate}</StyledCancellationDate>
        </>
      )}

      <StyledButtonContainer>
        <StyledButton variant="outline" onClick={handleExit}>
          <FormattedMessage id="cancellation.contract.cancel.exit" />
        </StyledButton>

        <StyledButton variant="primary" onClick={handleCancelContract} disabled={disableButton}>
          <FormattedMessage id="cancellation.contract.confirm.cancel" />
        </StyledButton>
      </StyledButtonContainer>
    </>
  );
}

// regular_cancelation_without_exported_payments_and_claims
function LandingPageContentsRegularCancelationWithoutExportedPaymentsAndClaims({
  handleExit,
  handleCancelContract,
  disableButton,
}) {
  const availableCancellationDate = useAvailableCancellationDate();

  return (
    <>
      <StyledLandingPageNormalText>
        <FormattedMessage id="cancellation.contract.landing.page.regular.text2" tagName="p" />
      </StyledLandingPageNormalText>

      {availableCancellationDate && (
        <>
          <StyledLandingPageBoldText>
            <FormattedMessage
              id="cancellation.contract.landing.page.regular.next_possible_date"
              tagName="p"
            />
          </StyledLandingPageBoldText>

          <StyledCancellationDate>{availableCancellationDate}</StyledCancellationDate>
        </>
      )}

      <StyledButtonContainer>
        <StyledButton variant="outline" onClick={handleExit}>
          <FormattedMessage id="cancellation.contract.cancel.exit" />
        </StyledButton>

        <StyledButton variant="primary" onClick={handleCancelContract} disabled={disableButton}>
          <FormattedMessage id="cancellation.contract.confirm.cancel" />
        </StyledButton>
      </StyledButtonContainer>
    </>
  );
}

// regular_cancelation_exported_payments_without_claims
function LandingPageContentsRegularCancelationExportedPaymentsWithoutClaims({
  handleExit,
  handleCancelContract,
  disableButton,
}) {
  const availableCancellationDate = useAvailableCancellationDate();

  return (
    <>
      <StyledLandingPageNormalText>
        <FormattedMessage id="cancellation.contract.landing.page.regular.text2" tagName="p" />
      </StyledLandingPageNormalText>

      {availableCancellationDate && (
        <>
          <StyledLandingPageBoldText>
            <FormattedMessage
              id="cancellation.contract.landing.page.regular.next_possible_date"
              tagName="p"
            />
          </StyledLandingPageBoldText>

          <StyledCancellationDate>{availableCancellationDate}</StyledCancellationDate>
        </>
      )}

      <StyledLandingPageNormalText>
        <FormattedMessage id="cancellation.contract.landing.page.regular.text3" tagName="p" />
      </StyledLandingPageNormalText>

      <StyledButtonContainer>
        <StyledButton variant="outline" onClick={handleExit}>
          <FormattedMessage id="cancellation.contract.cancel.exit" />
        </StyledButton>

        <StyledButton variant="primary" onClick={handleCancelContract} disabled={disableButton}>
          <FormattedMessage id="cancellation.contract.confirm.cancel" />
        </StyledButton>
      </StyledButtonContainer>
    </>
  );
}

// regular_cancelation_claims_without_exported_payments
function LandingPageContentsRegularCancelationClaimsWithoutExportedPayments({
  handleExit,
  handleCancelContract,
  disableButton,
}) {
  const availableCancellationDate = useAvailableCancellationDate();

  return (
    <>
      <StyledLandingPageNormalText>
        <FormattedMessage id="cancellation.contract.landing.page.regular.text2" tagName="p" />
      </StyledLandingPageNormalText>

      {availableCancellationDate && (
        <>
          <StyledLandingPageBoldText>
            <FormattedMessage
              id="cancellation.contract.landing.page.regular.next_possible_date"
              tagName="p"
            />
          </StyledLandingPageBoldText>

          <StyledCancellationDate>{availableCancellationDate}</StyledCancellationDate>
        </>
      )}

      <StyledLandingPageNormalText>
        <FormattedMessage id="cancellation.contract.landing.page.regular.text4" tagName="p" />
      </StyledLandingPageNormalText>

      <StyledButtonContainer>
        <StyledButton variant="outline" onClick={handleExit}>
          <FormattedMessage id="cancellation.contract.cancel.exit" />
        </StyledButton>

        <StyledButton variant="primary" onClick={handleCancelContract} disabled={disableButton}>
          <FormattedMessage id="cancellation.contract.confirm.cancel" />
        </StyledButton>
      </StyledButtonContainer>
    </>
  );
}

// regular_cancelation_with_exported_payments_and_claims
function LandingPageContentsRegularCancelationWithExportedPaymentsAndClaims({
  handleExit,
  handleCancelContract,
  disableButton,
}) {
  const availableCancellationDate = useAvailableCancellationDate();

  return (
    <>
      <StyledLandingPageNormalText>
        <FormattedMessage id="cancellation.contract.landing.page.regular.text2" tagName="p" />
      </StyledLandingPageNormalText>

      {availableCancellationDate && (
        <>
          <StyledLandingPageBoldText>
            <FormattedMessage
              id="cancellation.contract.landing.page.regular.next_possible_date"
              tagName="p"
            />
          </StyledLandingPageBoldText>

          <StyledCancellationDate>{availableCancellationDate}</StyledCancellationDate>
        </>
      )}

      <StyledLandingPageNormalText>
        <FormattedMessage id="cancellation.contract.landing.page.regular.text4" tagName="p" />
      </StyledLandingPageNormalText>

      <StyledLandingPageNormalText>
        <FormattedMessage id="cancellation.contract.landing.page.regular.text3" tagName="p" />
      </StyledLandingPageNormalText>

      <StyledButtonContainer>
        <StyledButton variant="outline" onClick={handleExit}>
          <FormattedMessage id="cancellation.contract.cancel.exit" />
        </StyledButton>

        <StyledButton variant="primary" onClick={handleCancelContract} disabled={disableButton}>
          <FormattedMessage id="cancellation.contract.confirm.cancel" />
        </StyledButton>
      </StyledButtonContainer>
    </>
  );
}

// regular_cancelation_with_im_coverage
function LandingPageContentsRegularCancelationWithImCoverage({
  handleExit,
  handleCancelContract,
  disableButton,
}) {
  const availableCancellationDate = useAvailableCancellationDate();

  return (
    <>
      <StyledLandingPageNormalText>
        <FormattedMessage id="cancellation.contract.landing.page.regular.text5" tagName="p" />
      </StyledLandingPageNormalText>

      {availableCancellationDate && (
        <>
          <StyledLandingPageBoldText>
            <FormattedMessage
              id="cancellation.contract.landing.page.regular.next_possible_date"
              tagName="p"
            />
          </StyledLandingPageBoldText>

          <StyledCancellationDate>{availableCancellationDate}</StyledCancellationDate>
        </>
      )}

      <StyledButtonContainer>
        <StyledButton variant="outline" onClick={handleExit}>
          <FormattedMessage id="cancellation.contract.cancel.exit" />
        </StyledButton>

        <StyledButton variant="primary" onClick={handleCancelContract} disabled={disableButton}>
          <FormattedMessage id="cancellation.contract.confirm.cancel" />
        </StyledButton>
      </StyledButtonContainer>
    </>
  );
}

// regular_cancelation_with_video_consultation
function LandingPageContentsRegularCancelationWithVideoConsultation({
  handleExit,
  handleCancelContract,
  disableButton,
}) {
  const availableCancellationDate = useAvailableCancellationDate();

  return (
    <>
      <StyledLandingPageNormalText>
        <FormattedMessage id="cancellation.contract.landing.page.regular.text1" tagName="p" />
      </StyledLandingPageNormalText>

      {availableCancellationDate && (
        <>
          <StyledLandingPageBoldText>
            <FormattedMessage
              id="cancellation.contract.landing.page.regular.next_possible_date"
              tagName="p"
            />
          </StyledLandingPageBoldText>

          <StyledCancellationDate>{availableCancellationDate}</StyledCancellationDate>
        </>
      )}

      <StyledButtonContainer>
        <StyledButton variant="outline" onClick={handleExit}>
          <FormattedMessage id="cancellation.contract.cancel.exit" />
        </StyledButton>

        <StyledButton variant="primary" onClick={handleCancelContract} disabled={disableButton}>
          <FormattedMessage id="cancellation.contract.confirm.cancel" />
        </StyledButton>
      </StyledButtonContainer>
    </>
  );
}

export default function LandingPageContents({
  cancellationPageKey,
  handleExit,
  handleCancelContract,
  disableButton,
}) {
  switch (cancellationPageKey) {
    case WITHDRAWAL_CANCELLATION_NO_PAYMENT_NO_CLAIM:
      return (
        <LandingPageContentsWithdrawalCancellationNoPaymentNoClaim
          handleExit={handleExit}
          handleCancelContract={handleCancelContract}
          disableButton={disableButton}
        />
      );
    case WITHDRAWAL_CANCELLATION_EXPORTED_PAYMENT_NO_CLAIM:
      return (
        <LandingPageContentsWithdrawalCancellationExportedPaymentNoClaim
          handleExit={handleExit}
          handleCancelContract={handleCancelContract}
          disableButton={disableButton}
        />
      );
    case WITHDRAWAL_CANCELLATION_WITH_CLAIM:
      return (
        <LandingPageContentsWithdrawalCancellationWithClaim
          handleExit={handleExit}
          handleCancelContract={handleCancelContract}
          disableButton={disableButton}
        />
      );
    case WITHDRAWAL_CANCELLATION_EXPORTED_PAYMENT_WITH_CLAIM:
      return (
        <LandingPageContentsWithdrawalCancellationExportedPaymentWithClaim
          handleExit={handleExit}
          handleCancelContract={handleCancelContract}
          disableButton={disableButton}
        />
      );
    case REGULAR_CANCELATION_INSIDE_MINIMUM_CONTRACT_DURATION_WITH_PAYOUT:
      return (
        <LandingPageContentsRegularCancelationInsideMinimumContractDurationWithPayout
          handleExit={handleExit}
          handleCancelContract={handleCancelContract}
          disableButton={disableButton}
        />
      );
    case REGULAR_CANCELATION_WITHOUT_EXPORTED_PAYMENTS_AND_CLAIMS:
      return (
        <LandingPageContentsRegularCancelationWithoutExportedPaymentsAndClaims
          handleExit={handleExit}
          handleCancelContract={handleCancelContract}
          disableButton={disableButton}
        />
      );
    case REGULAR_CANCELATION_EXPORTED_PAYMENTS_WITHOUT_CLAIMS:
      return (
        <LandingPageContentsRegularCancelationExportedPaymentsWithoutClaims
          handleExit={handleExit}
          handleCancelContract={handleCancelContract}
          disableButton={disableButton}
        />
      );
    case REGULAR_CANCELATION_CLAIMS_WITHOUT_EXPORTED_PAYMENTS:
      return (
        <LandingPageContentsRegularCancelationClaimsWithoutExportedPayments
          handleExit={handleExit}
          handleCancelContract={handleCancelContract}
          disableButton={disableButton}
        />
      );
    case REGULAR_CANCELATION_WITH_EXPORTED_PAYMENTS_AND_CLAIMS:
      return (
        <LandingPageContentsRegularCancelationWithExportedPaymentsAndClaims
          handleExit={handleExit}
          handleCancelContract={handleCancelContract}
          disableButton={disableButton}
        />
      );
    case REGULAR_CANCELATION_WITH_IM_COVERAGE:
      return (
        <LandingPageContentsRegularCancelationWithImCoverage
          handleExit={handleExit}
          handleCancelContract={handleCancelContract}
          disableButton={disableButton}
        />
      );
    case REGULAR_CANCELATION_WITH_VIDEO_CONSULTATION:
      return (
        <LandingPageContentsRegularCancelationWithVideoConsultation
          handleExit={handleExit}
          handleCancelContract={handleCancelContract}
          disableButton={disableButton}
        />
      );
    default:
      return null;
  }
}
