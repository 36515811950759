import styled from 'styled-components';

import { MIN_WIDTH_MD } from '../../theme/breakpoints';

const StyledPageContent = styled.div`
  flex: 1 0 auto;
  margin-bottom: 2em;

  @media (min-width: ${MIN_WIDTH_MD}) {
    margin-bottom: 4em;
  }
`;

export default StyledPageContent;
