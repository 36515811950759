import React from 'react';
import { FormattedMessage } from 'react-intl';

import { DA_DIREKT_REFERRAL_PAGE_LINK_CAROUSEL_DENTAL } from '../../../shared';

import { Button } from '../../../components/ui';
import { Separator, Icon } from '../../../components/common';
import {
  StyledPromotionCarouselCardContentImageWrapper,
  StyledPromotionCarouselButtonContainer,
  StyledDaDirektPromotionCarouselCard,
  StyledDaDirektPromotionCarouselCardContentTitle,
} from '../PromotionCarouselCard.styled';

const ReferralLinkCardDaDirektDental = () => {
  const handleGotoReferralPage = () => {
    window.open(DA_DIREKT_REFERRAL_PAGE_LINK_CAROUSEL_DENTAL, '_blank');
  };

  return (
    <StyledDaDirektPromotionCarouselCard>
      <StyledPromotionCarouselCardContentImageWrapper>
        <Icon icon="icon.user.referral.present" alt="referral link image" />
      </StyledPromotionCarouselCardContentImageWrapper>

      <StyledDaDirektPromotionCarouselCardContentTitle>
        <FormattedMessage id="promotion.carousel.da_direkt.dental.referral_link.title" />
      </StyledDaDirektPromotionCarouselCardContentTitle>

      <Separator style={{ marginTop: 0, marginBottom: 0 }} />

      <StyledPromotionCarouselButtonContainer>
        <Button onClick={handleGotoReferralPage}>
          <FormattedMessage id="promotion.carousel.dentolo.referral_link.button.label" />
        </Button>
      </StyledPromotionCarouselButtonContainer>
    </StyledDaDirektPromotionCarouselCard>
  );
};

export default ReferralLinkCardDaDirektDental;
