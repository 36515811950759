import styled, { css } from 'styled-components';

import { imgLinks, THEME_PETOLO, THEME_VITOLO } from '../../../shared';
import { MIN_WIDTH_MD } from '../../../theme/breakpoints';
import { color } from '../../../theme/functions';

import { ButtonContainer } from '../../../components/common';
import { Button } from '../../../components/ui';

export const StyledImageContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 0.5em;
  margin-top: 1em;

  @media (min-width: ${MIN_WIDTH_MD}) {
    margin-top: 2em;
  }
`;

export const StyledDesktopImage = styled.img`
  display: none;

  @media (min-width: ${MIN_WIDTH_MD}) {
    display: block;
  }
`;

export const StyledMobileImage = styled.img`
  display: block;

  @media (min-width: ${MIN_WIDTH_MD}) {
    display: none;
  }
`;

export const StyledButtonContainer = styled(ButtonContainer)`
  display: flex;
  flex-direction: column-reverse;

  @media (min-width: ${MIN_WIDTH_MD}) {
    flex-direction: row-reverse;
    column-gap: 1em;
  }
`;

export const StyledButton = styled(Button)`
  margin: 0.5em auto;
  width: 100%;

  @media (min-width: ${MIN_WIDTH_MD}) {
    margin: 0.5em;
  }
`;

export const StyledLandingPageNormalText = styled.div`
  font-weight: 500;
  text-align: center;
  margin-bottom: 1em;
  padding: 0 1em;
  color: ${color('main_blue')};

  p {
    font-size: 16px;
    line-height: 20px;
  }

  @media (min-width: ${MIN_WIDTH_MD}) {
    p {
      font-size: 20px;
      line-height: 26px;
    }
  }
`;

export const StyledLandingPageBoldText = styled(StyledLandingPageNormalText)`
  p {
    font-weight: 700;
  }
`;

const vitoloCancellationDateBackground = css`
  background-image: url(${imgLinks['icon.vitolo.underline']});
  background-repeat: no-repeat;
  background-size: contain;
  background-position: bottom;
  padding-bottom: 0.125em;
`;

const cancellationDateBackground = ({ theme }) => {
  const { name } = theme || {};

  const cancellationDateBgMap = {
    [THEME_PETOLO]: 'primary',
    [THEME_VITOLO]: 'transparent',
  };

  return color(cancellationDateBgMap[`${name}`] || 'secondary');
};

export const StyledCancellationDate = styled(StyledLandingPageBoldText)`
  width: fit-content;
  margin-left: auto;
  margin-right: auto;
  padding: 0 8px;
  font-size: 16px;
  line-height: 20px;
  font-weight: 700;
  background: ${cancellationDateBackground};
  ${({ theme: { name } }) => name === THEME_VITOLO && vitoloCancellationDateBackground};
  color: ${color('main_blue')};
  border-radius: 3px;

  @media (min-width: ${MIN_WIDTH_MD}) {
    font-size: 20px;
    line-height: 26px;
  }
`;
