import styled, { css } from 'styled-components';

import { MIN_WIDTH_MD } from '../../theme/breakpoints';
import { boxShadow, color } from '../../theme/functions';

const buttonContainerAlign = (align) =>
  ({
    left: css`
      > button {
        margin-left: 0;
      }
    `,
    center: css`
      > button {
        margin-left: auto;
        margin-right: auto;
      }
    `,
    right: css`
      > button {
        margin-left: auto;
        margin-right: 0;
      }
    `,
  })[`${align}`];

const StyledFormSectionContainer = styled.div`
  box-shadow: none;
  padding: 0em;
  background-color: transparent;
  border-radius: 0px;

  @media only screen and (min-width: ${MIN_WIDTH_MD}) {
    padding: 1em;
    border-radius: 5px;
    background-color: ${color('white')};
    box-shadow: ${boxShadow('box_shadow_1')};
  }
`;

const StyledErrorMessage = styled.div`
  margin-top: 0.5em;
  color: ${color('red')};
`;

const StyledButtonContainer = styled.div`
  margin-top: 1.5em;
  margin-bottom: 1.5em;
  ${({ align }) => buttonContainerAlign(align || 'center')};

  @media only screen and (min-width: ${MIN_WIDTH_MD}) {
    ${({ align }) => buttonContainerAlign(align || 'left')};
  }
`;

const StyledLabel = styled.label`
  display: block;
  margin-bottom: 0.5rem;
  font-size: 1rem;
  font-weight: 450;

  ${({ disabled }) =>
    disabled &&
    css`
      color: ${color('gray')};
    `}
`;

export {
  StyledFormSectionContainer as FormSectionContainer,
  StyledErrorMessage as ErrorMessage,
  StyledButtonContainer as ButtonContainer,
  StyledLabel as FormLabel,
};
