import styled, { css } from 'styled-components';

import { MIN_WIDTH_MD } from '../../../theme/breakpoints';
import { color } from '../../../theme/functions';

import { IconButton, Paper } from '../../common';
import Paragraph from '../Paragraph';
import { StyledButtonContainer } from './Button.styled';

export const StyledDentistFloatCard = styled(Paper)`
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  border-radius: 0;
  z-index: 50;

  @media (min-width: ${MIN_WIDTH_MD}) {
    display: none;
  }
`;

export const StyledDentistFloatCardWrapper = styled.div`
  position: relative;

  ${({ blurred }) =>
    blurred &&
    css`
      pointer-events: none;
      filter: blur(2px);
    `}
`;

export const StyledDentistFloatCardAvatar = styled.img`
  width: 3.5rem;
  height: 3.5rem;
  border-radius: 50%;
  border: 0.063rem solid ${color('ultra_light_gray')};
`;

export const StyledDentistFloatCardItem = styled(Paragraph)`
  display: flex;
  justify-content: flex-start;
  align-items: center;

  & + & {
    margin-top: 0.5rem;
  }

  > img {
    width: 1.125rem;
    height: 1.125rem;
  }

  > label {
    margin-left: 0.5rem;
    font-size: 0.875rem;
    color: ${color('main_blue')};

    a {
      color: ${color('main_blue')};
    }
  }
`;

export const StyledFavDentistIconButton = styled(StyledDentistFloatCardItem)`
  cursor: pointer;
  width: fit-content;

  > span {
    margin: 0 0 0 -3px;
    width: 1.5rem;
    height: 1.5rem;
    display: flex;
    justify-content: center;
    align-items: center;

    > img {
      width: 100%;
      height: 100%;

      ${({ isFavourite }) =>
        !isFavourite &&
        css`
          padding: 4px;
        `}
    }
  }

  > label {
    cursor: inherit;
  }
`;

export const StyledDentistFloatCardButtonContainer = styled(StyledButtonContainer)`
  flex-direction: row;
  justify-content: flex-end;
  column-gap: 1rem;

  > button {
    & + button {
      margin-top: 0;
    }
  }
`;

export const StyledDentistFloatCardCloseButton = styled(IconButton)`
  position: absolute;
  top: 0;
  right: 0;
  width: 0.75rem;
  height: 0.75rem;
  background: transparent;
`;

export const StyledBarLoaderWrapper = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
`;
