import styled, { keyframes } from 'styled-components';

import { INSURANCE_TYPE_PETOLO } from '../../../shared';
import { color } from '../../../theme/functions';

const overlayLogoSizeDentolo = '100%';
const overlayLogoSizePetolo = '1.5rem';

const animRect = ({ theme: { name } }) => keyframes`
  0% {
    height:${name === INSURANCE_TYPE_PETOLO ? overlayLogoSizePetolo : overlayLogoSizeDentolo}
  }

  70% {
    height: 0%;
  }

  100% {
    height: 0%;
  }
  `;

export const StyledLoadingContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 50vh;
`;

export const StyledLoadingText = styled.div`
  margin-top: 1rem;
  color: ${color('main_blue')};
`;

export const StyledLoadingSvgPetolo = styled.svg`
  fill: ${color('primary')};
`;

export const StyledLoadingSvgDentolo = styled.svg`
  width: 3.75rem;
  height: 3.75rem;
`;

export const StyledSvgRect = styled.rect`
  fill: ${color('viewport_background')};
  animation: ${animRect} 3.5s cubic-bezier(0.645, 0.045, 0.355, 1) 0s infinite;
`;
