import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';
import { useQuery } from '@apollo/client';

import GlobalStyles from './GlobalStyles';
import useApplicationTheme from './hooks/useApplicationTheme';
import useOptimizely from './hooks/useOptimizely';
import PreloadAssets from './components/ui/PreloadAssets';
import { palette } from './theme';
import { GET_CUSTOMER, THEME_DENTOLO } from './shared';

import Routes from './Routes';

function App() {
  const theme = useApplicationTheme();
  const themePalette = palette[theme || THEME_DENTOLO];
  const themeData = { name: theme, ...themePalette };

  const { data: customerData } = useQuery(GET_CUSTOMER);
  const { customer } = customerData || {};
  const { experimentTargetId } = customer || {};

  useOptimizely(experimentTargetId);

  return (
    <ThemeProvider theme={themeData}>
      <Router>
        <Routes />
      </Router>

      <GlobalStyles />
      <PreloadAssets />
    </ThemeProvider>
  );
}

export default App;
