import styled from 'styled-components';

import { MIN_WIDTH_MD } from '../../../theme/breakpoints';

import { Paper, Paragraph } from '../../../components/common';

export const StyledLinkToClaimsPagePaper = styled(Paper)`
  margin-bottom: 1.5rem;
  display: flex;
  flex-direction: row;
  text-align: left;

  @media (min-width: ${MIN_WIDTH_MD}) {
    flex-direction: column;
    text-align: center;
  }
`;

export const StyledLinkToClaimsPageIcon = styled.img`
  margin: 0;
  width: 2.5rem;
  height: 2.5rem;

  @media (min-width: ${MIN_WIDTH_MD}) {
    margin: 0 auto;
  }
`;

export const StyledLinkToClaimsPageContents = styled.div`
  margin-left: 1rem;
  margin-top: 0;

  @media (min-width: ${MIN_WIDTH_MD}) {
    margin-left: 0;
    margin-top: 1rem;
  }
`;

export const StyledLinkToClaimsPageTitle = styled(Paragraph)`
  margin-bottom: 1rem;
`;
