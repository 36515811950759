import React, { useState, useEffect } from 'react';
import styled from 'styled-components';

import { color } from '../../theme/functions';

export const GroupContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const StyledLabel = styled.label`
  display: flex;
  align-items: center;

  & + & {
    margin-top: 1rem;
  }

  > span {
    margin-left: 0.5rem;
    font-size: 1.25rem;
    color: ${color('main_blue')};
  }
`;

const RadioButtonGroup = (props) => {
  const {
    options = [],
    groupName = '',
    className = '',
    wrapperClassName = '',
    labelClassName = '',
    onChange = () => {},
  } = props;

  const [selected, setSelected] = useState('');

  const onRadioChange = (e) => {
    const selectedOption = e?.target?.value;
    setSelected(selectedOption);
    onChange(selectedOption);
  };

  useEffect(() => {
    const defaultSelected = options.find((option) => option.selected);

    if (defaultSelected) {
      setSelected(defaultSelected?.value);
    }
  }, [options]);

  return (
    <GroupContainer className={`${wrapperClassName || ''}`}>
      {groupName && <h4>{groupName}</h4>}

      {options.map((option) => {
        return (
          <StyledLabel
            key={option?.id}
            htmlFor={`radio-button-group-${option?.id}`}
            className={`${labelClassName || ''}`}
          >
            <input
              type="radio"
              id={`radio-button-group-${option?.id}`}
              name={option?.value}
              value={option?.value}
              className={`${className} input-primary input-md`}
              onChange={onRadioChange}
              checked={selected === option?.value}
            />

            <span>{option.caption}</span>
          </StyledLabel>
        );
      })}
    </GroupContainer>
  );
};

export default RadioButtonGroup;
