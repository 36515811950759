import React, { useState } from 'react';
import { useQuery } from '@apollo/client';
import { CSSTransition } from 'react-transition-group';
import { useIntl, FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';

import { useEditIcon } from '../hooks/useIcons';
import useInsuranceInfo from '../hooks/useInsuranceInfo';
import {
  GET_CUSTOMER,
  useReady,
  getBillingDays,
  INSURANCE_TYPE_PETOLO,
  PAYMENT_EDIT_STATUS_PENDING,
  PAYMENT_EDIT_STATUS_FAILED,
} from '../shared';

import { BackButton, Container, PageTitle } from './common';
import { Link, Paragraph } from './ui';
import CustomerDetailsEditFormWrapper from './CustomerDetails/CustomerDetailsEditFormWrapper';
import BankDetailsEditor from './CustomerDetails/BankDetailsEditor';
import CustomerDetailsEditor from './CustomerDetails/CustomerDetailsEditor';
import InsuredPersonDetailsEditor from './CustomerDetails/InsuredPersonDetailsEditor';
import PetDetailsEditor from './CustomerDetails/PetDetailsEditor';
import DigitalPaymentInfo from './CustomerDetails/DigitalPaymentInfo';
import DigitalPaymentLoading from './CustomerDetails/DigitalPaymentLoading';
import DigitalPaymentEditor from './CustomerDetails/DigitalPaymentEditor';
import {
  StyledCustomerDetailsButtonContainer,
  StyledCustomerDetailsCard,
  StyledCustomerDetailsCardTitle,
  StyledCustomerDetailsCardTitleButtonWrapper,
  StyledCustomerDetailsItem,
  StyledCustomerDetailsModalButton,
  StyledPaymentErrorContainer,
} from './styled/CustomerDetails.styled';

const CustomerDetails = () => {
  const ready = useReady();
  const getCustomer = useQuery(GET_CUSTOMER);
  const intl = useIntl();

  const { type: insuranceType } = useInsuranceInfo();

  const editIcon = useEditIcon();

  const [showBankDetailsEditor, setShowBankDetailsEditor] = useState(false);
  const [showCustomerDetailsEditor, setShowCustomerDetailsEditor] = useState(false);
  const [showInsuredPersonDetailsEditor, setShowInsuredPersonDetailsEditor] = useState(false);
  const [showPetDetailsEditor, setShowPetDetailsEditor] = useState(false);
  const [showDigitalPaymentEditor, setShowDigitalPaymentEditor] = useState(false);

  const { customer, digitalPaymentMethod, latestDigitalPaymentMethod } = getCustomer.data;
  const {
    gender,
    firstName,
    lastName,
    email,
    phoneNumber,
    streetName,
    houseNumber,
    postcode,
    city,
    addressDetails,
    bankAccountFirstName,
    bankAccountLastName,
    iban,
    insuredPersonIsCustomer,
    insuredPerson,
    insuredPet,
    contract: { billingDay },
  } = customer;

  const { financialInstrument, active: hasActiveDigitalPaymentMethod } = digitalPaymentMethod || {};
  const { status } = latestDigitalPaymentMethod || {};

  const insuredPersonGender = insuredPerson?.gender;
  const insuredPersonFirstName = insuredPerson?.firstName;
  const insuredPersonLastName = insuredPerson?.lastName;
  const insuredPersonStreetName = insuredPerson?.streetName;
  const insuredPersonHouseNumber = insuredPerson?.houseNumber;
  const insuredPersonPostcode = insuredPerson?.postcode;
  const insuredPersonCity = insuredPerson?.city;

  const billingDayText = billingDay
    ? getBillingDays(intl).find((val) => val.value === billingDay)?.label
    : '';

  if (getCustomer.loading) return null;

  return (
    <>
      <CustomerDetailsEditFormWrapper
        isOpen={showBankDetailsEditor}
        title={
          hasActiveDigitalPaymentMethod
            ? intl.formatMessage({ id: 'bank.details.edit_refund_account.modal.title' })
            : intl.formatMessage({ id: 'bank.details.form.title' })
        }
        hasActiveDigitalPaymentMethod={hasActiveDigitalPaymentMethod}
        onClose={() => setShowBankDetailsEditor(false)}
        data={{
          firstName: bankAccountFirstName,
          lastName: bankAccountLastName,
          iban,
          billingDay,
        }}
        formComponent={<BankDetailsEditor />}
      />

      <CustomerDetailsEditFormWrapper
        isOpen={showCustomerDetailsEditor}
        title={intl.formatMessage({ id: 'personal.details.form.title' })}
        onClose={() => setShowCustomerDetailsEditor(false)}
        data={{
          gender,
          firstName,
          lastName,
          email,
          phoneNumber,
          streetName,
          houseNumber,
          postcode,
          city,
          addressDetails,
        }}
        formComponent={<CustomerDetailsEditor />}
      />

      {!insuredPersonIsCustomer && (
        <CustomerDetailsEditFormWrapper
          isOpen={showInsuredPersonDetailsEditor}
          title={intl.formatMessage({ id: 'insured.person.details.form.title' })}
          onClose={() => setShowInsuredPersonDetailsEditor(false)}
          data={{
            gender: insuredPersonGender,
            firstName: insuredPersonFirstName,
            lastName: insuredPersonLastName,
            streetName: insuredPersonStreetName,
            houseNumber: insuredPersonHouseNumber,
            postcode: insuredPersonPostcode,
            city: insuredPersonCity,
          }}
          formComponent={<InsuredPersonDetailsEditor />}
        />
      )}

      {insuranceType === INSURANCE_TYPE_PETOLO && (
        <CustomerDetailsEditFormWrapper
          isOpen={showPetDetailsEditor}
          title={intl.formatMessage({ id: 'pet.details.form.title' })}
          onClose={() => setShowPetDetailsEditor(false)}
          data={{
            name: insuredPet?.name,
            transponderCode: insuredPet?.transponderCode,
          }}
          formComponent={<PetDetailsEditor />}
        />
      )}

      {digitalPaymentMethod && hasActiveDigitalPaymentMethod && (
        <CustomerDetailsEditFormWrapper
          isOpen={showDigitalPaymentEditor}
          title={intl.formatMessage({ id: 'customer.details.digital.payment.edit.title' })}
          onClose={() => setShowDigitalPaymentEditor(false)}
          formComponent={<DigitalPaymentEditor />}
        />
      )}

      <CSSTransition in={ready} timeout={600} classNames="slow-fade" unmountOnExit>
        <Container>
          <BackButton to="/policy-info" />

          <PageTitle>
            <FormattedMessage id="policy.details.my.policy" />
          </PageTitle>

          <StyledCustomerDetailsCard>
            <StyledCustomerDetailsCardTitleButtonWrapper>
              <StyledCustomerDetailsCardTitle noSpacing>
                <FormattedMessage id="policy.details.my.contact.info" />
              </StyledCustomerDetailsCardTitle>

              <StyledCustomerDetailsModalButton
                icon={editIcon}
                size="sm"
                onClick={() => setShowCustomerDetailsEditor(true)}
                aria-label="Edit customer details"
              />
            </StyledCustomerDetailsCardTitleButtonWrapper>

            <DetailsItem
              labelId="policy.details.contact.info.name"
              text={`${firstName} ${lastName}`}
            />
            <DetailsItem labelId="policy.details.contact.info.email" text={email} />
            <DetailsItem labelId="policy.details.contact.info.phone" text={phoneNumber} />
            <DetailsItem
              labelId="policy.details.contact.info.address"
              text={`${streetName} ${houseNumber}, ${postcode} ${city}`}
            />
            {addressDetails && (
              <DetailsItem
                labelId="policy.details.contact.info.address_details"
                text={addressDetails}
              />
            )}
          </StyledCustomerDetailsCard>

          {!insuredPersonIsCustomer && (
            <StyledCustomerDetailsCard>
              <StyledCustomerDetailsCardTitleButtonWrapper>
                <StyledCustomerDetailsCardTitle noSpacing>
                  <FormattedMessage id="policy.details.insured.person.info.title" />
                </StyledCustomerDetailsCardTitle>

                <StyledCustomerDetailsModalButton
                  icon={editIcon}
                  size="sm"
                  onClick={() => setShowInsuredPersonDetailsEditor(true)}
                  aria-label="Edit insured person details"
                />
              </StyledCustomerDetailsCardTitleButtonWrapper>

              <DetailsItem
                labelId="policy.details.insured.person.info.name"
                text={`${insuredPersonFirstName} ${insuredPersonLastName}`}
              />

              <DetailsItem
                labelId="policy.details.insured.person.info.address"
                text={`${insuredPersonStreetName} ${insuredPersonHouseNumber}, ${insuredPersonPostcode} ${insuredPersonCity}`}
              />
            </StyledCustomerDetailsCard>
          )}

          {insuranceType === INSURANCE_TYPE_PETOLO && (
            <StyledCustomerDetailsCard>
              <StyledCustomerDetailsCardTitleButtonWrapper>
                <StyledCustomerDetailsCardTitle noSpacing>
                  <FormattedMessage id="policy.details.pet.info.title" />
                </StyledCustomerDetailsCardTitle>

                <StyledCustomerDetailsModalButton
                  icon={editIcon}
                  size="sm"
                  onClick={() => setShowPetDetailsEditor(true)}
                  aria-label="Edit pet details"
                />
              </StyledCustomerDetailsCardTitleButtonWrapper>

              <DetailsItem labelId="policy.details.pet.info.name" text={`${insuredPet?.name}`} />

              <DetailsItem
                labelId="policy.details.pet.info.transponder_code"
                text={
                  insuredPet?.transponderCode ? (
                    `${insuredPet?.transponderCode}`
                  ) : (
                    <FormattedMessage id="policy.details.pet.info.transponder_code.missing" />
                  )
                }
              />
            </StyledCustomerDetailsCard>
          )}
          {/* digital payments */}
          {digitalPaymentMethod && hasActiveDigitalPaymentMethod && (
            <StyledCustomerDetailsCard>
              <StyledCustomerDetailsCardTitleButtonWrapper>
                <StyledCustomerDetailsCardTitle noSpacing>
                  <FormattedMessage id="policy.details.my.digital.payment.info" />
                </StyledCustomerDetailsCardTitle>
                <StyledCustomerDetailsModalButton
                  icon={editIcon}
                  size="sm"
                  onClick={() => setShowDigitalPaymentEditor(true)}
                  aria-label="Edit payment data"
                />
              </StyledCustomerDetailsCardTitleButtonWrapper>
              {/* error placeholder */}
              {status === PAYMENT_EDIT_STATUS_FAILED && (
                <StyledPaymentErrorContainer>
                  <Paragraph style={{ fontSize: '0.875rem' }}>
                    <FormattedMessage id="policy.details.account.digital.payment.error" />
                  </Paragraph>
                </StyledPaymentErrorContainer>
              )}
              <Paragraph style={{ marginBottom: '1rem', fontSize: '1rem' }}>
                <FormattedMessage id="policy.details.account.digital.payment.desc" />
              </Paragraph>
              {status === PAYMENT_EDIT_STATUS_PENDING && <DigitalPaymentLoading />}
              {status !== PAYMENT_EDIT_STATUS_PENDING && (
                <DigitalPaymentInfo financialInstrument={financialInstrument} />
              )}

              {billingDay && (
                <DetailsItem
                  labelId="policy.details.billing.info.billing_day"
                  text={billingDayText}
                />
              )}
            </StyledCustomerDetailsCard>
          )}

          <StyledCustomerDetailsCard>
            <StyledCustomerDetailsCardTitleButtonWrapper>
              <StyledCustomerDetailsCardTitle noSpacing>
                {hasActiveDigitalPaymentMethod ? (
                  <FormattedMessage id="policy.details.my.refund_account.info" />
                ) : (
                  <FormattedMessage id="policy.details.my.billing.info" />
                )}
              </StyledCustomerDetailsCardTitle>

              <StyledCustomerDetailsModalButton
                icon={editIcon}
                size="sm"
                onClick={() => setShowBankDetailsEditor(true)}
                aria-label="Edit customer data"
              />
            </StyledCustomerDetailsCardTitleButtonWrapper>

            <Paragraph style={{ marginBottom: '1rem', fontSize: '1rem' }}>
              {hasActiveDigitalPaymentMethod ? (
                <FormattedMessage id="policy.details.my.refund_account.desc" />
              ) : (
                <FormattedMessage id="policy.details.account.billing.desc" />
              )}
            </Paragraph>

            <DetailsItem
              labelId="policy.details.billing.info.account_holder"
              text={`${bankAccountFirstName || ''} ${bankAccountLastName || ''}`}
            />

            <DetailsItem labelId="policy.details.billing.info.iban" text={iban} />
            {(!digitalPaymentMethod || !digitalPaymentMethod.active) && billingDay && (
              <DetailsItem
                labelId="policy.details.billing.info.billing_day"
                text={billingDayText}
              />
            )}
          </StyledCustomerDetailsCard>

          <Paragraph type="subtext" style={{ marginTop: '1rem' }}>
            <FormattedMessage id="policy.details.found.errors" />
          </Paragraph>

          <Paragraph type="subtext" style={{ marginTop: '0.5rem' }}>
            <strong>
              <FormattedMessage id="policy.details.contact.us" />
            </strong>
          </Paragraph>

          <StyledCustomerDetailsButtonContainer>
            <Link to="/contact">
              <FormattedMessage id="policy.details.to.contact" />
            </Link>
          </StyledCustomerDetailsButtonContainer>
        </Container>
      </CSSTransition>
    </>
  );
};

export default CustomerDetails;

const DetailsItem = ({ labelId, text }) => {
  return (
    <StyledCustomerDetailsItem>
      <div data-label>
        <FormattedMessage id={labelId} />:
      </div>

      <div data-value>{text}</div>
    </StyledCustomerDetailsItem>
  );
};

DetailsItem.propTypes = {
  labelId: PropTypes.string.isRequired,
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
};
