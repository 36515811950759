import styled, { css } from 'styled-components';

import { color } from '../../theme/functions';
import { MIN_WIDTH_MD } from '../../theme/breakpoints';

import { Icon, Paper } from '../common';

export const StyledMissingIbanBannerContainer = styled(Paper)`
  position: relative;
  background-color: ${color('ultra_light_orange')};
  ${({ dismissable }) =>
    dismissable &&
    css`
      border-left: 5px solid;
      border-color: ${color('primary')};
      background-color: transparent;
    `};
`;

export const StyledMissingIbanBannerBody = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  text-align: center;
  ${({ dismissable }) =>
    dismissable &&
    css`
      margin-right: 1.75rem;
      flex-direction: row;
      text-align: left;
    `}
`;

export const StyledMissingIbanBannerTitle = styled.div`
  font-size: 1rem;
  color: ${color('main_blue')};
  margin-bottom: 0.5rem;
  font-weight: 700;
  @media (min-width: ${MIN_WIDTH_MD}) {
    font-size: 1.188rem;
  }
`;

export const StyledMissingIbanBannerText = styled.span`
  font-size: 1rem;
  color: ${color('main_blue')};

  ${({ dismissable }) =>
    dismissable &&
    css`
      color: ${color('professional_light_blue')};
    `}
  ${({ $title }) =>
    $title &&
    css`
      margin-bottom: 0.5rem;
      font-weight: 700;
      color: ${color('main_blue')};
    `}

  ${({ noSpacing }) =>
    noSpacing &&
    css`
      margin-bottom: 0;
    `}

  @media (min-width: ${MIN_WIDTH_MD}) {
    font-size: 1.188rem;
  }
`;

export const StyledMissingIbanBannerIcon = styled(Icon)`
  width: 1.75rem;
  height: 1.75rem;
  margin-right: 1rem;
`;

export const StyledDissmissButton = styled.button`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 1rem;
  background: transparent;
  border: none;
  cursor: pointer;

  > img {
    width: 0.625rem;
    height: 0.625rem;
  }
`;
