import styled from 'styled-components';

import { MIN_WIDTH_MD } from '../../../theme/breakpoints';

export const StyledFressnapfFriendsCardContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: stretch;
  width: 100%;
`;

export const StyledFressnapfFriendsCardImageWrapper = styled.div`
  flex-basis: 8rem;
  background: transparent;
  overflow: hidden;

  @media (min-width: ${MIN_WIDTH_MD}) {
    flex-basis: 16rem;
  }
`;

export const StyledFressnapfFriendsCardImage = styled.img`
  width: auto;
  height: 100%;
`;

export const StyledFressnapfFriendsCardContent = styled.div`
  flex: 1;
  padding: 1rem 1rem 0;

  @media (min-width: ${MIN_WIDTH_MD}) {
    padding-top: 2.5rem;
    padding-right: 2.5rem;
    padding-left: 2.5rem;
  }
`;

export const StyledFressnapfFriendsCardText = styled.div`
  font-size: 1.125rem;

  @media (min-width: ${MIN_WIDTH_MD}) {
    font-size: 1.5rem;
  }
`;

export const StyledFressnapfFriendsCardButtonContainer = styled.div`
  margin-top: 1.5rem;

  > button {
    padding-left: 1.375rem;
    padding-right: 1.375rem;
  }
`;
