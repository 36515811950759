import React, { useRef } from 'react';
import styled from 'styled-components';
import { useIntl } from 'react-intl';

import { MIN_WIDTH_MD } from '../../theme/breakpoints';

import Input from './Input';

export const StyledFormGroupInputSegments = styled.div`
  display: flex;
  width: 100%;
`;

export const StyledDateInput = styled(Input)`
  flex-grow: 1;
  height: 3.5em;
`;

export const StyledMonthInput = styled(Input)`
  flex-grow: 3;
  height: 3.5rem;
  margin: 0 0.5em;

  @media only screen and (min-width: ${MIN_WIDTH_MD}) {
    margin: 0 3em;
  }
`;

export const StyledYearInput = styled(Input)`
  flex-grow: 1;
  height: 3.5rem;
`;

const DatePicker = ({ day, month, year, setDay, setMonth, setYear }) => {
  const intl = useIntl();

  const dayInputRef = useRef(null);
  const monthInputRef = useRef(null);
  const yearInputRef = useRef(null);
  const handleDayChange = (event) => {
    if (/^([0-9]\d*)$/.test(event.target.value) || event.target.value === '') {
      if (event.target.value.length <= 2) {
        setDay(event.target.value);
      }

      if (event.target.value.length > 1) {
        monthInputRef.current.focus();
      }
    }
  };
  const handleMonthChange = (event) => {
    if (/^([0-9]\d*)$/.test(event.target.value) || event.target.value === '') {
      if (event.target.value.length <= 2) {
        setMonth(event.target.value);
      }

      if (event.target.value.length > 1) {
        yearInputRef.current.focus();
      }
    }
  };
  const handleYearChange = (event) => {
    if (/^([0-9]\d*)$/.test(event.target.value) || event.target.value === '') {
      if (event.target.value.length <= 4) {
        setYear(event.target.value);
      }
    }
  };
  return (
    <StyledFormGroupInputSegments>
      <StyledDateInput
        ref={dayInputRef}
        type="text"
        name="date"
        inputMode="numeric"
        value={day}
        onChange={handleDayChange}
        placeholder={intl.formatMessage({
          id: 'dentolo.day_placeholder',
        })}
        autoComplete="off"
        min="1"
        max="31"
        step="1"
        data-testid="day"
        required
        autoFocus
      />

      <StyledMonthInput
        ref={monthInputRef}
        type="text"
        name="month"
        inputMode="numeric"
        value={month}
        onChange={handleMonthChange}
        placeholder={intl.formatMessage({
          id: 'dentolo.month_placeholder',
        })}
        autoComplete="off"
        min="1"
        max="12"
        step="1"
        data-testid="month"
        required
      />

      <StyledYearInput
        ref={yearInputRef}
        type="text"
        name="year"
        inputMode="numeric"
        value={year}
        onChange={handleYearChange}
        placeholder={intl.formatMessage({
          id: 'dentolo.year_placeholder',
        })}
        autoComplete="off"
        step="1"
        data-testid="year"
        required
      />
    </StyledFormGroupInputSegments>
  );
};

export default DatePicker;
