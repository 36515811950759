import styled, { css } from 'styled-components';

import { color } from '../../../theme/functions';

import { Icon } from '../../common';
import {
  navMenuUnderlineActiveBaseStyle,
  navMenuUnderlineBaseStyle,
} from './NavMenuDesktop.styled';

export const StyledNavMenuAccordionToggleLabel = styled.div`
  position: relative;
  font-size: 1rem;
  font-weight: 700;
  color: ${color('main_blue')};

  ${navMenuUnderlineBaseStyle};
  &::after {
    bottom: -1px;
  }
`;

export const StyledNavMenuAccordionToggleArrowIcon = styled(Icon)`
  width: 1.25rem;
  height: auto;
  transition:
    transform 0.15s ease-out,
    opacity 0.2s ease-out;

  ${({ rotate }) =>
    rotate &&
    css`
      transform: rotate(180deg);
    `}
`;

export const StyledNavMenuAccordionToggle = styled.div`
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.4rem 1rem;
  min-height: 3rem;
  cursor: pointer;

  ${({ expanded, active }) =>
    (expanded || active) &&
    css`
      ${StyledNavMenuAccordionToggleLabel} {
        color: ${color('professional_light_blue')};
        ${navMenuUnderlineActiveBaseStyle};
      }

      ${StyledNavMenuAccordionToggleArrowIcon} {
        opacity: 0.5;
      }
    `}
`;

export const StyledNavMenuAccordion = styled.div`
  margin: 0;
  background-color: ${color('white')};
  border-bottom: 1px solid ${color('gray_blue')};
  max-height: 6rem;
  transition: max-height 0.35s ease-in;
  overflow: hidden;

  ul {
    margin: 0;
    padding-left: 0;
  }

  ${({ expanded }) =>
    expanded &&
    css`
      max-height: 70rem;
    `}

  ${({ type }) =>
    type === 'user' &&
    css`
      background: transparent;

      ${StyledNavMenuAccordionToggle} {
        padding-top: 0.875rem;
        padding-bottom: 0.875rem;
      }
    `}
`;

export const StyledNavMenuAccordionContent = styled.div`
  padding: 1rem;
`;

export const StyledUserContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  column-gap: 1rem;
`;
