import styled from 'styled-components';

import { MIN_WIDTH_MD } from '../../theme/breakpoints';

export const StyledUserClaimsSlideGroupContainer = styled.div`
  position: relative;
  min-height: 20rem;
`;

export const StyledNewReimbursementButtonContainer = styled.div`
  display: flex;
  align-items: flex-start;
  margin-bottom: 1.5em;

  & > a {
    margin-left: auto;
    margin-right: auto;
  }

  & > a[disabled] {
    pointer-events: none;
  }

  @media (min-width: ${MIN_WIDTH_MD}) {
    & > a {
      margin-left: 0;
    }
  }
`;
