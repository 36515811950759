import styled, { css } from 'styled-components';

import { MAX_WIDTH_SM } from '../../../theme/breakpoints';
import { color, boxShadow } from '../../../theme/functions';
import { THEME_DENTOLO, THEME_PETOLO, THEME_VITOLO } from '../../../shared';

import { Button } from '../../ui';

export const StyledNavUser = styled.div`
  position: relative;
  display: none;

  @media (min-width: ${MAX_WIDTH_SM}) {
    display: initial;
  }
`;

export const StyledNavUserPopup = styled.div`
  position: absolute;
  right: 0;
  top: 2.5rem;
  display: flex;
  flex-direction: column;
  min-width: 20.25rem;
  border-radius: 5px;
  background-color: ${color('white')};
  box-shadow: ${boxShadow('box_shadow_1')};

  ul {
    margin: 0;
    padding-left: 0;
    max-height: 360px;
    overflow-y: auto;

    > li {
      list-style: none;
    }
  }
`;

const backgroundColor = (themeName) => {
  const bgColorMap = {
    [THEME_DENTOLO]: color('ultra_clear_light_blue'),
    [THEME_PETOLO]: color('ultra_light_gray'),
    [THEME_VITOLO]: color('ultra_clear_light_violet'),
  };

  return bgColorMap[`${themeName}`] || color('ultra_clear_light_blue');
};

export const StyledNavUserMenuItem = styled.li`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem;
  min-height: 4rem;
  background-color: ${color('white')};
  cursor: pointer;
  transition: background 0.3s ease-in-out;

  &:hover {
    background-color: ${({ theme }) => backgroundColor(theme?.name)};
  }

  &:first-child {
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
  }

  ${({ inActive }) =>
    inActive &&
    css`
      opacity: 0.4;
    `}

  >img {
    width: 2rem;
    height: auto;
  }
`;

export const StyledNavUserMenuItemDetails = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  column-gap: 1rem;

  > img {
    margin-left: 0.5rem;
    width: 2rem;
    height: auto;
  }

  [data-title] {
    font-size: 1.25rem;
    font-weight: 700;
  }

  [data-policy] {
    margin-top: 0.2rem;
    font-size: 0.8rem;
  }
`;

export const LogoutButton = styled(Button)`
  margin-left: auto;
  height: 2.25rem;
  font-size: 1rem;

  @media (max-width: ${MAX_WIDTH_SM}) {
    background-color: ${color('white')};
    margin-left: auto;
    height: 3rem;
    border: none;
    border-radius: 5px;
    box-shadow: ${boxShadow('box_shadow_2')};
  }
`;
